.login {
  padding: 24px;
  padding-bottom: 102px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  position: relative;
  height: 100%;
}

.login__heading {
  font-size: 24px;
  font-weight: 500;
  color: var(--dark-text-color);
}

.login__phone {
  margin-top: 32px;
  margin-bottom: 32px;
}

.login__legal {
  text-align: center;
  color: var(--dark-text-color);
}

.login__legal a {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}

.login__continue {
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
  padding: 16px;
  font-size: 16px;
  width: calc(100% - 48px);
  box-sizing: border-box;
  border-radius: 16px;
  outline: none;
  border: none;
  background-color: #0000A1;
  color: #fff;
  transition: background .2s;
}

.login__continue:disabled {
  background-color: #B7C0E5;
}

.phone-otp__resend {
  display: block;
  padding: 16px;
  border-radius: 16px;
  outline: none;
  border: none;
  background-color: #F2F4FA;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  color: var(--black-lectogo-color);
  transition: all .2s;
}

.phone-otp__label {
  color: #7A8099;
  font-size: 14px;
}

.phone-otp__resend:disabled {
  background-color: #ccc;
  color: #aaa;
}

.phone-otp__countdown {
  font-size: 14px;
  color: #7A8099;
  text-align: center;
  margin-top: 8px;
}
