@font-face {
    font-family: 'Space Grotesk';
    src: url('SpaceGrotesk-SemiBold.woff2') format('woff2'),
        url('SpaceGrotesk-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Grotesk';
    src: url('SpaceGrotesk-Light.woff2') format('woff2'),
        url('SpaceGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Grotesk';
    src: url('SpaceGrotesk-Medium.woff2') format('woff2'),
        url('SpaceGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Grotesk';
    src: url('SpaceGrotesk-Regular.woff2') format('woff2'),
        url('SpaceGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Grotesk';
    src: url('SpaceGrotesk-Bold.woff2') format('woff2'),
        url('SpaceGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

