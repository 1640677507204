.social button {
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  color: var(--black-lectogo-color);
  background-color: #F2F4FA;
  outline: none;
  border: none;
  padding: 16px;
  width: 100%;
  border-radius: 16px;
}

.social + .social {
  margin-top: 8px;
}

.social__icon {
  vertical-align: middle;
  margin-right: 12px;
}

.social__text {
  vertical-align: middle;
}
