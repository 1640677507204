.lease {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 24px;
}

.lease__content {
  padding: 24px;
}

.lease__bottom {
  flex-shrink: 0;
  background-color: #fff;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  width: 100vw;
  margin-left: -24px;
  margin-bottom: -24px;
  padding: 24px;
  box-shadow: 0px -2px 8px rgba(32, 31, 92, 0.1);
  margin-top: auto;
}

.lease__pay {
  color: #fff;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  padding: 16px;
  outline: none;
  border: none;
  border-radius: 16px;
  background-color: #0000A1;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  line-height: 20px;
}

.lease__pay:disabled {
  background-color: #7777A1;
}

.pay__icon {
  height: 20px;
  vertical-align: middle;
}

.pay__text {
  margin-left: 8px;
}

.success-icon {
  max-width: 16px;
}

.spinner-icon {
  animation: spin .6s linear infinite;
}

.lease__cards {
  flex-shrink: 1;
  overflow: auto;
}

.lease__heading {
  font-size: 24px;
  font-weight: 500;
  color: var(--dark-text-color);
  flex-shrink: 0;
}

.lease__info {
  box-sizing: border-box;
  width: 100%;
  background-color: violet;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  color: #000059;
  background-color: #F2F4FA;
  border-radius: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
  border: 2px solid #eee;
}

.info__price {
  color: #7A8099;
}

.lease__label {
  font-size: 14px;
  font-weight: 500;
  color: #7A8099;
}

.cards-list__item {
  background-color: #F2F4FA;
  border-radius: 12px;
  border: none;
  outline: none;
  display: block;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align-last: left;
  font-family: inherit;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  color: var(--dark-text-color);
  box-sizing: border-box;
  border: 2px solid transparent;
  line-height: 32px;
  position: relative;
}

.cards-list__item--selected {
  border: 2px solid #0092FF;
}

.cards-list__radio {
  position: absolute;
	opacity: 0;
	width: 0;
}

.item__logo {
  margin-right: 18px;
  max-width: 32px;
  height: 32px;
  vertical-align: middle;
}

.card-element {
  padding: 16px;
  border: 1px solid #B7C0E6;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: #FAFBFF;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;
}

.card-expiry {
  margin-right: 16px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row div {
  width: 100%;
}

.add-button {
  font-size: 18px;
  font-weight: 700;
  padding: 16px;
  background-color: #0000A1;
  border-radius: 16px;
  color: #fff;
  outline: none;
  border: none;
  width: 100%;
}

.add-card-modal {
  position: fixed;
  top: 0; bottom: 0; left: 0; right: 0;
  padding: 30px 25px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.add-card-modal__wrapper {
  border-radius: 16px;
  position: relative;
  background-color: #fff;
  padding: 30px 25px;
  box-sizing: border-box;
  width: 100%;
}

.lease-list__item {
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 16px;
  background-color: #F2F4FA;
  border-radius: 12px;
  box-sizing: border-box;
  width: 100%;
  color: var(--dark-text-color);
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.item__info {
  font-size: 14px;
  color: #7a8099;
}

.item__info b {
  font-weight: 500;
  color: #0000A1;
}

.meta__label {
  color: #7A8099;
  font-size: 12px;
}

.item__meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.meta__entry {
  margin-bottom: 8px;
}

.col {
  display: flex;
  flex-direction: column;
}

.item__progress {
  margin-top: 16px;
  margin-bottom: 12px;
}

.progress-bar {
  margin-top: 6px;
  padding: 9px 12px;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 80%)
}

.no-leases {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #B7C0E6;
  font-weight: 500;
  font-size: 24px;
}


.loading {
  text-align: center;
  padding: 20px;
}

.loading .spinner-icon {
  height: 26px;
  text-align: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
}
