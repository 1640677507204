.banner {
  --light-text-color: #F2F4FA;
  --link-color: #0000A1;

  width: 100vw;
  box-sizing: border-box;
  font-weight: 700;
  padding: 28px 24px 24px 24px;
  text-align: center;

  background: url(../assets/img/lightning.svg) center no-repeat, radial-gradient(148% 68.35% at 50% 12.07%, #2538E9 0%, #0000A1 100%);
  background-size: cover;
}

.banner__wrapper {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
}

.banner__text {
  color: var(--light-text-color);
  font-size: 24px;
}

.banner__links {
  font-size: 16px;
  width: 100%;
}

.banner__link {
  background-color: var(--light-text-color);
  border-radius: 16px;
  padding: 16px;
  display: block;
  color: var(--link-color);
  text-decoration: none;
}

.banner__link span {
  line-height: 26px;
  vertical-align: middle;
}

.banner__link + .banner__link {
  margin-top: 8px;
}

.banner__link img {
  vertical-align: middle;
  margin-right: 12px;
  height: 24px;
}
