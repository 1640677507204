.phone {
  font-size: 16px;
}

.phone__input {
  background-color: #FAFBFF;
  border-radius: 12px;
  border: 2px solid #B7C0E6;
  font-weight: 400;
  color: var(--dark-text-color);
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.input__country {
  border-right: 1px solid #B7C0E6;
}

.country__flag {
  font-size: 18px;
  vertical-align: middle;
}

.input__country button {
  background-color: inherit;
  outline: none;
  border: none;
  font-weight: 400;
  color: var(--dark-text-color);
  font-size: inherit;
  font-family: inherit;
  padding: 16px;
  padding-right: 8px;
}

.country__code {
  vertical-align: middle;
}

.input__number {
  vertical-align: middle;
  padding: 16px;
  padding-left: 8px;
  width: 100%;
}

.input__number input {
  background-color: inherit;
  outline: none;
  border: none;
  font-weight: 400;
  color: var(--dark-text-color);
  font-size: inherit;
  padding: 0;
  margin: 0;
  font-family: inherit;
  width: 100%;
}

.wrapper__heading {
  text-align: center;
  font-size: 22px;
}

.wrapper__list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  text-align: left;
}

.list__item + .list__item{
  margin-top: 15px;
}

.list__item button {
  text-align: left;
}

.item__flag {
  font-size: 24px;
  margin-right: 10px;
  vertical-align: middle;
}

.list__item button {
  font-size: 16px;
  background-color: inherit;
  outline: none;
  border: none;
}

.modal__wrapper {
  border-radius: 16px;
  position: relative;
  background-color: #fff;
  padding: 30px 25px;
  overflow-y: scroll;
}

.modal__close {
  background-color: #FAFBFF;
  padding: 5px;
  margin: 10px;
  font-size: 18px;
  border-radius: 8px;
  outline: none;
  border: none;
}

.phone__label {
  color: #7A8099;
  font-size: 14px;
  margin-bottom: 8px;
}

.phone__input-focused {
  border: 2px solid #0092FF;
}

.phone-otp {
  margin-top: 32px;
}

.phone-otp__label {
  margin-bottom: 8px;
}

.phone-otp__input {
  margin-bottom: 32px;
}

.wrapper__search {
  width: 100%;
  border: none;
  padding: 16px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #B7C0E6;
  color: var(--dark-text-color);
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  border-radius: 12px;
}
